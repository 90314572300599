import { Controller } from "@hotwired/stimulus"

import { type GiftMessageDetail } from "./gift_message_form_controller"

export default class GiftMessagePreviewController extends Controller<HTMLElement> {
  static targets = ["message", "recipientName", "senderName"]

  declare readonly messageTarget: HTMLElement
  declare readonly recipientNameTarget: HTMLElement
  declare readonly senderNameTarget: HTMLElement

  clearAll(): void {
    this.setMessage("")
    this.setRecipientName("")
    this.setSenderName("")
    this.element.style.backgroundImage = ""
  }

  setAll({ message, recipientName, senderName, theme }: GiftMessageDetail): void {
    this.setMessage(message)
    this.setRecipientName(recipientName)
    this.setSenderName(senderName)
    this.setThemeImages(theme.leftImage, theme.rightImage)
  }

  setRecipientName(value: string): void {
    this.recipientNameTarget.textContent = value ? `To ${value}` : ""
  }

  setSenderName(value: string): void {
    this.senderNameTarget.textContent = value ? `from ${value}` : ""
  }

  setMessage(value: string): void {
    this.messageTarget.textContent = value
  }

  setThemeImages(leftImage: string, rightImage: string): void {
    const backgroundStyle = `url('${leftImage}'), url('${rightImage}')`

    this.element.style.backgroundImage = backgroundStyle
  }
}
