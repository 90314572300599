import { Controller } from "@hotwired/stimulus"

const INVALID_CHARACTERS = /[^0-9+\-() ]/g

const EMPTY_FIELD_MESSAGE = "Please fill this field"
const PHONE_INCORRECT_FORMAT = "Phone number should contain at least 9 digits"
const ERROR_SELECTOR = ".utils\\:error"

export default class PhoneFieldController extends Controller<HTMLInputElement> {
  restrictInput(): void {
    const cursorPosition = this.element.selectionStart
    const value = this.element.value.replace(INVALID_CHARACTERS, "")

    if (this.element.value !== value) {
      if (cursorPosition !== null) {
        const newCursorPosition = Math.min(
          cursorPosition - (this.element.value.length - value.length),
          value.length
        )
        this.element.value = value
        this.element.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    }
  }

  setErrorMessage(): void {
    this.element.checkValidity()
    const errorSpan = this.element.parentElement!.querySelector(ERROR_SELECTOR)!

    if (this.element.validity.valueMissing) {
      errorSpan.textContent = EMPTY_FIELD_MESSAGE
    } else {
      errorSpan.textContent = PHONE_INCORRECT_FORMAT
    }
  }
}
