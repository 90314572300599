import { Controller } from "@hotwired/stimulus"

import ModalController from "@/controllers/modal_controller"
import type { DeliveryOption } from "@/helpers/delivery_options"
import * as deliveryOptionsHelper from "@/helpers/delivery_options"
import { formatPrice } from "@/helpers/price_format"
import DiscountCodeController from "../discount_code_controller"
import type { DeliveryOptionEvent } from "./delivery_options_controller"
import type { QuantityEvent } from "./quantity_counter_controller"

export default class VoucherPaymentSummaryController extends Controller<HTMLInputElement> {
  static outlets = ["discount-code", "discount-modal"]
  declare readonly discountCodeOutlet: DiscountCodeController
  declare readonly discountModalOutlet: ModalController

  static targets = [
    "addDiscount",
    "appliedDiscountRow",
    "appliedPromoCodeInput",
    "deliveryOptionName",
    "deliveryOptionPrice",
    "formInputDeliveryName",
    "formInputDeliveryPrice",
    "formInputDeliverySupplierCode",
    "formInputOrderPrice",
    "formInputPostagePrice",
    "giftVoucherTotal",
    "klarnaPrice",
    "loader",
    "postagePrice",
    "postageRow",
    "postageQuantityText",
    "quantityText",
    "totalPrice"
  ]

  declare readonly addDiscountTarget: HTMLButtonElement
  declare readonly appliedDiscountRowTarget: HTMLElement
  declare readonly appliedPromoCodeInputTarget: HTMLInputElement
  declare readonly hasAppliedPromoCodeInputTarget: boolean
  declare readonly deliveryOptionNameTarget: HTMLElement
  declare readonly deliveryOptionPriceTarget: HTMLElement
  declare readonly formInputDeliveryNameTarget: HTMLInputElement
  declare readonly formInputDeliveryPriceTarget: HTMLInputElement
  declare readonly formInputDeliverySupplierCodeTarget: HTMLInputElement
  declare readonly formInputOrderPriceTarget: HTMLInputElement
  declare readonly formInputPostagePriceTarget: HTMLInputElement
  declare readonly giftVoucherTotalTarget: HTMLInputElement
  declare readonly klarnaPriceTarget: HTMLElement
  declare readonly loaderTarget: HTMLElement
  declare readonly postagePriceTarget: HTMLElement
  declare readonly postageRowTarget: HTMLElement
  declare readonly postageQuantityTextTarget: HTMLElement
  declare readonly quantityTextTarget: HTMLElement
  declare readonly totalPriceTarget: HTMLElement

  static values = {
    deliveryOption: Object,
    discountAmount: Number,
    quantity: Number,
    voucherPrice: Number
  }

  declare deliveryOptionValue: DeliveryOption
  declare discountAmountValue: number
  declare quantityValue: number
  declare voucherPriceValue: number

  appliedPromoCodeInputTargetConnected(element: HTMLElement): void {
    this.loaderTarget.hidden = true
    this.addDiscountTarget.hidden = true

    this.discountModalOutlet.close()

    if (element.dataset.amount) {
      this.discountAmountValue = parseFloat(element.dataset.amount)

      this.updateSummary()
    }
  }

  quantityValueChanged(_current: number, previous: number): void {
    // Don't update on initial load
    if (!previous) {
      return
    }

    this.discountCodeOutlet.updateApplicableVoucherPrice(this.giftVoucherTotal / 100)

    // If promo code is present, start the loader animation and re-apply the promo to reflect the
    // new quantity. That returns a Turbo stream, causing `appliedPromoCodeInputTargetConnected`
    // to update the summary. So we only update summary here if there's no promo.
    if (this.appliedPromoCode) {
      this.loaderTarget.hidden = false
      this.discountCodeOutlet.applyPromoToVoucher(this.appliedPromoCode)
    } else {
      this.updateSummary()
    }
  }

  deliveryOptionValueChanged(): void {
    this.updateSummary()
    this.toggleFormInputs()
  }

  removeDiscount(): void {
    this.discountAmountValue = 0
    this.loaderTarget.hidden = true

    this.updateSummary()
    this.addDiscountTarget.hidden = false
    this.appliedDiscountRowTarget.replaceChildren()
  }

  setQuantity(event: QuantityEvent): void {
    this.quantityValue = event.detail.count
  }

  setDeliveryOption({ detail }: DeliveryOptionEvent): void {
    this.deliveryOptionValue = detail.option
  }

  private get appliedPromoCode(): string | null {
    // This target only present when returned by Turbo stream on successful promo code application.
    return this.hasAppliedPromoCodeInputTarget ? this.appliedPromoCodeInputTarget.value : null
  }

  private updateDeliveryOptionRow(): void {
    this.deliveryOptionNameTarget.innerText = this.deliveryOptionText
    this.formInputDeliveryNameTarget.value = this.deliveryOptionValue.name
    this.formInputDeliverySupplierCodeTarget.value = this.deliveryOptionValue.supplierCode

    if (this.isEVoucher) {
      this.deliveryOptionPriceTarget.innerText = "FREE"
      this.formInputDeliveryPriceTarget.removeAttribute("value")
    } else {
      this.deliveryOptionPriceTarget.innerText = this.formattedPrice(
        this.deliveryPricePence * this.quantityValue
      )
      this.formInputDeliveryPriceTarget.value = this.deliveryPricePence.toString()
    }
  }

  private updatePostageRow(): void {
    if (this.postagePrice) {
      this.postagePriceTarget.innerText = this.formattedPrice(this.postagePrice)
      this.postageRowTarget.hidden = false
      this.formInputPostagePriceTarget.value = this.postagePrice.toString()

      if (deliveryOptionsHelper.isGiftCard(this.deliveryOptionValue)) {
        this.postageQuantityTextTarget.innerText = ""
      } else {
        this.postageQuantityTextTarget.innerText = this.quantityText()
      }
    } else {
      this.postagePriceTarget.innerText = ""
      this.postageQuantityTextTarget.innerText = ""
      this.postageRowTarget.hidden = true
      this.formInputPostagePriceTarget.removeAttribute("value")
    }
  }

  private updateGiftVoucherRow(): void {
    this.giftVoucherTotalTarget.innerText = this.formattedPrice(this.giftVoucherTotal)
  }

  private updateSummary(): void {
    this.updateGiftVoucherRow()
    this.updateDeliveryOptionRow()
    this.updatePostageRow()
    this.updateTotal()
  }

  private updateTotal(): void {
    const totalPrice = Math.max(this.totalPrice - this.discountAmountValue, 0)

    this.formInputOrderPriceTarget.value = this.orderPrice.toString()

    this.totalPriceTarget.innerText = this.formattedPrice(totalPrice)
    this.klarnaPriceTarget.innerText = this.formattedPrice(totalPrice / 3.0)
    this.quantityTextTarget.innerText = this.quantityText()
  }

  private get giftVoucherTotal(): number {
    return this.voucherPriceValue * this.quantityValue
  }

  private get orderPrice(): number {
    return deliveryOptionsHelper.orderPrice(
      this.voucherPriceValue,
      this.deliveryOptionValue,
      this.quantityValue
    )
  }

  private get totalPrice(): number {
    return deliveryOptionsHelper.totalPrice(
      this.voucherPriceValue,
      this.deliveryOptionValue,
      this.quantityValue
    )
  }

  private get postagePrice(): number | null {
    return deliveryOptionsHelper.postagePrice(this.deliveryOptionValue, this.quantityValue)
  }

  private get deliveryPricePence(): number {
    return deliveryOptionsHelper.pricePence(this.deliveryOptionValue)
  }

  private formattedPrice(pricePence: number): string {
    return formatPrice(pricePence / 100.0)
  }

  private quantityText(): string {
    if (this.quantityValue === 1) {
      return ""
    } else {
      return `${this.quantityValue} x `
    }
  }

  private get deliveryOptionText(): string {
    const { name } = this.deliveryOptionValue
    const quantity = this.quantityValue

    if (this.isEVoucher || quantity === 1) {
      return name
    } else {
      return `${quantity} x ${name}`
    }
  }

  private get isEVoucher(): boolean {
    return deliveryOptionsHelper.isEVoucher(this.deliveryOptionValue)
  }

  private toggleFormInputs(): void {
    const isDisabled = this.isEVoucher

    this.formInputDeliveryNameTarget.disabled = isDisabled
    this.formInputDeliveryPriceTarget.disabled = isDisabled
    this.formInputPostagePriceTarget.disabled = isDisabled
    this.formInputDeliverySupplierCodeTarget.disabled = isDisabled
  }
}
