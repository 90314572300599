/**
 * Markup:
 *
 * <label class="ui-component-input">
 *   <input>
 *   <span class="input-label">Message</span>
 *   <span class="utils:error"></span>
 * </label>
 */

const ERROR_CLASS = ".utils\\:error"
const INVALID_CLASS = "invalid"

const labelFor = (element: HTMLElement): HTMLLabelElement => {
  return element.closest("label") as HTMLLabelElement
}

const toggleValidity = (inputOrError: HTMLElement, condition: boolean): void => {
  labelFor(inputOrError).classList.toggle(INVALID_CLASS, !condition)
}

const setErrorMessage = (inputOrError: HTMLElement, message: string): void => {
  const errorElement = labelFor(inputOrError).querySelector(ERROR_CLASS)!

  toggleValidity(inputOrError, message === "")
  errorElement.textContent = message
}

type Validator = (input: HTMLInputElement) => boolean

const validateInput = (input: HTMLInputElement, validator: Validator, message: string): boolean => {
  const valid = validator(input)
  if (!valid) {
    setErrorMessage(input, message)
  }

  return valid
}

const clearError = (inputOrError: HTMLInputElement): void => {
  setErrorMessage(inputOrError, "")
}

export { validateInput, clearError }
