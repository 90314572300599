import { Controller } from "@hotwired/stimulus"

export default class EvoucherDeliveryController extends Controller<HTMLElement> {
  static targets = [
    "dateInput",
    "deliveryScheduleOption",
    "email",
    "specificDateRadio",
    "specificDateLabel"
  ]

  declare readonly emailTarget: HTMLInputElement
  declare readonly dateInputTarget: HTMLInputElement
  declare readonly deliveryScheduleOptionTargets: HTMLInputElement[]
  declare readonly specificDateRadioTarget: HTMLInputElement
  declare readonly specificDateLabelTarget: HTMLSpanElement

  toggleVisible(show: boolean): void {
    this.element.hidden = !show
    this.emailTarget.disabled = !show
    this.emailTarget.value = ""
    this.deliveryScheduleOptionTargets.forEach((target) => (target.disabled = !show))
  }

  handleSpecificDate(e: Event): void {
    // do not select the radio until a date has been chosen in the picker
    e.preventDefault()
    this.dateInputTarget.showPicker()
  }

  // Called when user selects a date in the calendar.
  setSpecificDate(): void {
    const value = this.dateInputTarget.value

    if (value === "") {
      // User must have clicked "clear" in date picker
      this.clearSpecificDate()
      this.deliveryScheduleOptionTargets[0].checked = true
    } else {
      // User has selected a date
      this.specificDateRadioTarget.checked = true
      this.specificDateRadioTarget.value = value
      this.specificDateLabelTarget.innerHTML = `<strong>Custom:</strong> ${this.formatDateString(value)} (9am)`
    }
  }

  clearSpecificDate(): void {
    this.specificDateLabelTarget.innerHTML = "Specific date..."
    this.specificDateRadioTarget.value = ""
    this.dateInputTarget.value = ""
  }

  private formatDateString(dateString: string): string {
    const date = new Date(dateString)

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short"
    }

    return date.toLocaleDateString("en-GB", options)
  }
}
