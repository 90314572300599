import { Controller } from "@hotwired/stimulus"
import PhysicalDeliveryController from "@/controllers/checkout/voucher_bookings/physical_delivery_controller"

const HIDDEN_FIELD_SELECTOR = "input[type=hidden][name=form_validity]"
const PAYMENT_BUTTON_SELECTOR = "button[form=checkout-form][type=submit]"
const ICON_SELECTOR = ".far"

export default class CheckoutFormController extends Controller<HTMLFormElement> {
  static outlets = ["physical-delivery"]

  declare readonly physicalDeliveryOutlet: PhysicalDeliveryController
  declare readonly hasPhysicalDeliveryOutlet: boolean

  static targets = ["telephone"]

  declare readonly telephoneTarget: HTMLInputElement

  connect(): void {
    this.clearLoading()
  }

  validateRequiredField(event: Event): void {
    const target = event.target as HTMLInputElement

    target.value = target.value.trim()
  }

  submit(event: Event): void {
    this.dispatch("validate")

    if (!this.checkValidity()) {
      document.getElementById("turbo-form-error-notice")?.removeAttribute("hidden")
      event.preventDefault()

      if (this.hasPhysicalDeliveryOutlet) {
        this.physicalDeliveryOutlet.showAddressFormOnError() // make sure errors in address details are visible
      }
    } else {
      this.setLoading()
    }
  }

  setLoading(): void {
    const button = document.querySelector(PAYMENT_BUTTON_SELECTOR) as HTMLButtonElement
    const icon = button.querySelector(ICON_SELECTOR)
    button.disabled = true
    icon?.classList.remove("fa-arrow-right")
    icon?.classList.add("fa-spinner", "fa-spin")
  }

  clearLoading(): void {
    const button = document.querySelector(PAYMENT_BUTTON_SELECTOR) as HTMLButtonElement
    const icon = button.querySelector(ICON_SELECTOR)

    button.disabled = false
    icon?.classList.add("fa-arrow-right")
    icon?.classList.remove("fa-spinner", "fa-spin")
  }

  checkValidity(): boolean {
    return (
      this.element.checkValidity() &&
      this.checkHiddenFieldsValid() &&
      this.checkDeliveryAddressValidity()
    )
  }

  checkHiddenFieldsValid(): boolean {
    const hiddenInputs = Array.from(
      this.element.querySelectorAll(HIDDEN_FIELD_SELECTOR)
    ) as HTMLInputElement[]

    return hiddenInputs.every((hiddenInput) => hiddenInput.validity.valid)
  }

  private checkDeliveryAddressValidity(): boolean {
    if (!this.hasPhysicalDeliveryOutlet) return true

    return this.physicalDeliveryOutlet.checkValidity()
  }
}
