import { ActionEvent, Controller } from "@hotwired/stimulus"
import PhysicalDeliveryController from "@/controllers/checkout/voucher_bookings/physical_delivery_controller"
import EvoucherDeliveryController from "@/controllers/checkout/voucher_bookings/evoucher_delivery_controller"
import { isEVoucher } from "@/helpers/delivery_options"
import type { DeliveryOption } from "@/helpers/delivery_options"

export type DeliveryOptionEvent = CustomEvent<DeliveryOptionDetail>

export interface DeliveryOptionDetail {
  option: DeliveryOption
}

export default class DeliveryOptionsController extends Controller {
  static outlets = ["physical-delivery", "evoucher-delivery"]

  declare readonly physicalDeliveryOutlet: PhysicalDeliveryController
  declare readonly evoucherDeliveryOutlet: EvoucherDeliveryController

  static targets = ["radio"]
  static values = { selectedOption: Object }

  declare selectedOptionValue: DeliveryOption
  declare readonly radioTarget: HTMLInputElement
  declare readonly radioTargets: HTMLInputElement[]

  selectDeliveryOption({ params }: ActionEvent): void {
    const isOutOfStock = params.outOfStock as boolean

    if (!isOutOfStock) {
      this.selectedOptionValue = params.option as DeliveryOption
    }
  }

  selectedOptionValueChanged(currentOption: DeliveryOption, previousOption: DeliveryOption): void {
    const isCurrentEvoucher = isEVoucher(currentOption)
    const isPreviousEvoucher = isEVoucher(previousOption)
    const hasVoucherTypeChanged = isCurrentEvoucher !== isPreviousEvoucher

    this.radioTargets.find((target) => target.value === currentOption.name)!.checked = true

    if (hasVoucherTypeChanged) {
      this.evoucherDeliveryOutlet.toggleVisible(isCurrentEvoucher)
      this.physicalDeliveryOutlet.toggleVisible(!isCurrentEvoucher)
    }

    this.dispatch("setDeliveryOption", { detail: { option: currentOption } })
  }

  selectDeliveryOptionFromModal(event: Event): void {
    const button = event.currentTarget as HTMLElement
    const modalDiv = button.closest(".ui-component-modal") as HTMLElement
    const deliveryOptionDiv = modalDiv.previousElementSibling as HTMLDivElement

    deliveryOptionDiv.click()
  }

  showModal(event: ActionEvent): void {
    event.stopPropagation()
    const { supplierCode } = event.params
    this.dispatch("showModal-" + supplierCode)
  }
}
