import { Controller } from "@hotwired/stimulus"

import ModalController from "@/controllers/modal_controller"
import { validateInput, clearError } from "@/helpers/form_validation"
import { isBackForwardNavigation } from "@/helpers/utils"
import GiftMessagePreviewController from "./gift_message_preview_controller"

export interface GiftMessageDetail {
  message: string
  recipientName: string
  senderName: string
  theme: GiftMessageTheme
}

interface GiftMessageTheme {
  name: string
  leftImage: string
  rightImage: string
}

export default class GiftMessageFormController extends Controller {
  static outlets = ["gift-message-modal", "gift-message-preview"]
  declare readonly giftMessageModalOutlet: ModalController
  declare readonly giftMessagePreviewOutlet: GiftMessagePreviewController

  static targets = [
    "input",
    "leftImageInput",
    "messageInput",
    "recipientInput",
    "remainingCharacters",
    "rightImageInput",
    "senderInput",
    "themeRadio"
  ]
  declare readonly inputTargets: HTMLInputElement[]
  declare readonly leftImageInputTarget: HTMLInputElement
  declare readonly messageInputTarget: HTMLInputElement
  declare readonly recipientInputTarget: HTMLInputElement
  declare readonly remainingCharactersTarget: HTMLSpanElement
  declare readonly rightImageInputTarget: HTMLInputElement
  declare readonly senderInputTarget: HTMLInputElement
  declare readonly themeRadioTargets: HTMLInputElement[]

  static values = {
    savedDetails: Object,
    themes: Array
  }
  declare savedDetailsValue: GiftMessageDetail
  declare readonly themesValue: GiftMessageTheme[]

  connect(): void {
    this.savedDetailsValue = this.emptyDetails
  }

  handleMessageInput(): void {
    this.giftMessagePreviewOutlet.setMessage(this.messageInputTarget.value.trim())
    this.clearMessageErrorAndUpdateHint()
  }

  handleSenderNameInput(): void {
    this.giftMessagePreviewOutlet.setSenderName(this.senderInputTarget.value.trim())
  }

  handleRecipientNameInput(): void {
    this.giftMessagePreviewOutlet.setRecipientName(this.recipientInputTarget.value.trim())
  }

  loadCachedData(): void {
    // We set an action on `pageshow` instead of running this in `#intialize` because Stimulus
    // has problems detecting cached input values: https://github.com/hotwired/stimulus/issues/328

    // Do nothing if page wasn't loaded from cache
    if (!isBackForwardNavigation()) {
      return
    }

    // If message present in input, save it and update preview
    if (this.messageInputTarget.value !== "") {
      // Before saving message, trigger checked theme radio so theme images are set
      this.checkThemeRadio(this.checkedThemeRadio.value)
      this.saveMessage()
      this.giftMessagePreviewOutlet.setAll(this.savedDetailsValue)
    }
  }

  loadSavedData(): void {
    this.setInputs(this.savedDetailsValue)
    this.clearMessageErrorAndUpdateHint()
  }

  resetForm(): void {
    this.setInputs(this.emptyDetails)
    this.clearMessageErrorAndUpdateHint()
  }

  themeChanged({ currentTarget }: IEvent<HTMLInputElement>): void {
    const theme = this.themesValue.find((theme) => theme.name === currentTarget.value)!

    this.leftImageInputTarget.value = theme.leftImage
    this.rightImageInputTarget.value = theme.rightImage

    this.giftMessagePreviewOutlet.setThemeImages(theme.leftImage, theme.rightImage)
  }

  validate(): void {
    if (this.validateMessagePresence(this.messageInputTarget)) {
      this.saveMessage()
      this.giftMessageModalOutlet.close()
    }
  }

  removeMessage(): void {
    this.savedDetailsValue = this.emptyDetails

    this.resetForm()
  }

  private get defaultTheme(): GiftMessageTheme {
    return this.themesValue.at(0)!
  }

  private get emptyDetails(): GiftMessageDetail {
    return {
      message: "",
      recipientName: "",
      senderName: "",
      theme: this.defaultTheme
    }
  }

  private saveMessage(): void {
    this.savedDetailsValue = {
      message: this.messageInputTarget.value.trim(),
      recipientName: this.recipientInputTarget.value.trim(),
      senderName: this.senderInputTarget.value.trim(),
      theme: {
        name: this.checkedThemeRadio.value,
        leftImage: this.leftImageInputTarget.value,
        rightImage: this.rightImageInputTarget.value
      }
    }

    this.dispatch("saveMessage", { detail: this.savedDetailsValue })
  }

  private get maxLength(): number {
    return this.messageInputTarget.maxLength
  }

  private validateMessagePresence(input: HTMLInputElement): boolean {
    return validateInput(
      input,
      (input) => input.value.trim() !== "",
      "Please fill out this field in order to save a gift message."
    )
  }

  private checkThemeRadio(name: string): void {
    this.themeRadioTargets
      .find((radio) => {
        if (radio.value === name) {
          radio.checked = true
          return true
        }
        return false
      })!
      .dispatchEvent(new Event("change"))
  }

  private get checkedThemeRadio(): HTMLInputElement {
    return this.themeRadioTargets.find((radio) => radio.checked)!
  }

  private clearMessageErrorAndUpdateHint(): void {
    const remaining = this.maxLength - this.messageInputTarget.value.length

    this.remainingCharactersTarget.textContent = Math.max(0, remaining).toString()
    clearError(this.messageInputTarget)
  }

  private setInputs(detail: GiftMessageDetail): void {
    this.recipientInputTarget.value = detail.recipientName
    this.senderInputTarget.value = detail.senderName
    this.messageInputTarget.value = detail.message

    this.checkThemeRadio(detail.theme.name)

    this.giftMessagePreviewOutlet.setAll(detail)
  }
}
