import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  valueToUpperCase(e: Event): void {
    const input = e.target as HTMLInputElement
    input.value = input.value.toUpperCase()
  }

  valueToNumber(e: Event): void {
    const input = e.target as HTMLInputElement
    input.value = input.value.replace(/[^\d]/g, "")
  }

  selectAllText(e: Event): void {
    const input = e.target as HTMLInputElement
    input.select()
  }

  showFindCodeModal(): void {
    this.dispatch("showFindCodeModal")
  }

  closeFindCodeModal(): void {
    this.dispatch("hideFindCodeModal")
  }
}
