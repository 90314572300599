import { ActionEvent, Controller } from "@hotwired/stimulus"
import { fetchUrl } from "@/api/generic_fetch"

type AddressResult = {
  id: string
  address: string
}

type AddressDetails = {
  address1: string
  address2?: string
  company?: string
  city: string
  postcode: string
}

export default class extends Controller<HTMLElement> {
  static targets = [
    "addressResultTemplate",
    "postcodeLookup",
    "addressDetails",
    "addressResults",
    "alert",
    "postcodeNotFoundAlert",
    "addressNotFoundAlert",
    "enterAddressButton",
    "addressLine1Field",
    "addressLine2Field",
    "postcodeField",
    "cityField",
    "findAddressButton",
    "formInput"
  ]

  declare readonly addressResultTemplateTarget: HTMLTemplateElement

  declare readonly formInputTargets: HTMLInputElement[]

  declare readonly postcodeLookupTarget: HTMLInputElement
  declare readonly addressResultsTarget: HTMLElement
  declare readonly addressDetailsTarget: HTMLFieldSetElement
  declare readonly enterAddressButtonTarget: HTMLButtonElement
  declare readonly findAddressButtonTarget: HTMLButtonElement

  declare readonly addressLine1FieldTarget: HTMLInputElement
  declare readonly addressLine2FieldTarget: HTMLInputElement
  declare readonly postcodeFieldTarget: HTMLInputElement
  declare readonly cityFieldTarget: HTMLInputElement

  declare readonly alertTargets: HTMLElement[]
  declare readonly postcodeNotFoundAlertTarget: HTMLElement
  declare readonly addressNotFoundAlertTarget: HTMLElement

  toggleVisible(show: boolean): void {
    this.element.hidden = !show
    this.formInputTargets.forEach((target) => {
      target.disabled = !show
      target.setCustomValidity("")
      target.value = ""
    })
  }

  validateField(event: Event): void {
    const target = event.target as HTMLInputElement

    this.checkFieldValidity(target)
  }

  checkValidity(): boolean {
    if (this.element.hidden) return true

    return this.formInputTargets.every((target) => this.checkFieldValidity(target))
  }

  validateAllFields(): void {
    // Validate all fields and show error messages
    this.formInputTargets.forEach((target) => {
      this.checkFieldValidity(target)
    })
  }

  enterAddressManually(): void {
    this.showAddressForm()
    this.toggleResultsList(false)
    this.clearResults()
  }

  showAddressForm(): void {
    this.enterAddressButtonTarget.setAttribute("hidden", "true")
    this.addressDetailsTarget.removeAttribute("hidden")
  }

  showAddressFormOnError(): void {
    if (this.element.hidden) return

    this.showAddressForm()
    this.validateAllFields()
  }

  clearResults(): void {
    this.addressResultsTarget.replaceChildren()
  }

  toggleResultsList(show: boolean): void {
    this.addressResultsTarget.parentElement!.hidden = !show
  }

  clearAlerts(): void {
    this.alertTargets.forEach((target) => (target.hidden = true))
  }

  async submitPostcode(): Promise<void> {
    this.findAddressButtonTarget.disabled = true

    const postcodeValue = this.postcodeLookupTarget.value

    try {
      const data = await fetchUrl(`/voucher_bookings/postcode_lookup?postcode=${postcodeValue}`)

      if (Array.isArray(data) && data.length > 0) {
        this.enterAddressButtonTarget.removeAttribute("hidden")
        this.addressDetailsTarget.setAttribute("hidden", "true")
        this.createAddressResults(data as AddressResult[])
      } else {
        this.postcodeNotFound()
      }
    } catch {
      this.postcodeNotFound()
    }

    this.findAddressButtonTarget.disabled = false
  }

  async retrieveAddress({ params }: ActionEvent): Promise<void> {
    this.addressResultsTarget.querySelectorAll("input").forEach((input) => (input.disabled = true))

    try {
      const data = await fetchUrl(`/voucher_bookings/retrieve_address?id=${params.id}`)

      this.enterAddressButtonTarget.setAttribute("hidden", "true")

      this.populateAddressFields(data as AddressDetails)
      this.toggleResultsList(false)
      this.clearResults()
      this.clearAlerts()
    } catch {
      this.addressNotFound()
    }
  }

  private postcodeNotFound(): void {
    this.postcodeNotFoundAlertTarget.removeAttribute("hidden")
    this.enterAddressManually()
  }

  private addressNotFound(): void {
    this.clearAlerts()

    this.addressNotFoundAlertTarget.removeAttribute("hidden")
    this.postcodeLookupTarget.value = ""

    this.enterAddressManually()
  }

  // Delivery address fields must be validated manually using the setCustomValidity method.
  // These fields are part of the #checkout-form but are not required when the eVoucher delivery option is selected.
  // Although the `disabled` attribute prevents submission of these fields, the combination of `disabled` and `required` causes validation issues when the form is submitted and invalid.
  // When the user switches to the physical delivery option, which removes the `disabled` attribute and makes the fields visible and required, the browser marks them as invalid — even if they haven’t been touched — because the form has already been submitted.
  // This behavior is unexpected and could confuse users.
  private checkFieldValidity(target: HTMLInputElement): boolean {
    const isRequired = target.closest("label")?.classList.contains("required")

    if (isRequired && target.value.trim() === "") {
      target.setCustomValidity("Please fill this field")
    } else {
      target.setCustomValidity("")
    }

    this.setErrorMessage(target)

    return target.validity.valid
  }

  private setErrorMessage(target: HTMLInputElement): void {
    target.checkValidity()

    const errorSpan = target.parentElement!.querySelector(".utils\\:error")!

    if (target.validity.customError) {
      errorSpan.textContent = target.validationMessage
    }
  }

  private populateAddressFields(address: AddressDetails): void {
    this.postcodeLookupTarget.value = ""
    this.addressDetailsTarget.removeAttribute("hidden")

    this.addressLine1FieldTarget.value = this.buildAddress1(address)
    this.addressLine2FieldTarget.value = address.address2 || ""
    this.cityFieldTarget.value = address.city
    this.postcodeFieldTarget.value = address.postcode

    this.validateAllFields()
  }

  private buildAddress1(address: AddressDetails): string {
    if (address.company && address.company.trim().length > 0) {
      return `${address.company}, ${address.address1}`
    } else {
      return address.address1
    }
  }

  private createAddressResults(results: AddressResult[]): void {
    const fragment = document.createDocumentFragment()

    results.forEach((result) => {
      fragment.appendChild(this.createAddressResultFragment(result))
    })

    this.clearResults()
    this.addressResultsTarget.appendChild(fragment)
    this.toggleResultsList(true)
  }

  private createAddressResultFragment(result: AddressResult): DocumentFragment {
    const template = this.addressResultTemplateTarget.content.cloneNode(true) as DocumentFragment

    template.querySelector("span")!.innerText = result.address
    template.querySelector("input")!.setAttribute("data-physical-delivery-id-param", result.id)

    return template
  }
}
