const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  fn: F,
  wait = 500
): ((...args: Parameters<F>) => void) => {
  let timeoutId: number

  return (...args: Parameters<F>): void => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => fn(...args), wait)
  }
}

const isBackForwardNavigation = (): boolean => {
  const navEntries = window.performance.getEntriesByType(
    "navigation"
  ) as PerformanceNavigationTiming[]

  return navEntries.at(0)?.type === "back_forward"
}

// unindent a multiline string
const unindent = (str: string): string => str.replace(/\s{2,}/g, "\n\n").trim()

export { debounce, isBackForwardNavigation, unindent }
