import { Controller } from "@hotwired/stimulus"

import { type GiftMessageDetail } from "./gift_message_form_controller"
import GiftMessagePreviewController from "./gift_message_preview_controller"

import { type StimulusDispatchOptions } from "@/entry_points/types"

export default class GiftMessageSectionController extends Controller {
  static outlets = ["gift-message-preview"]
  declare readonly giftMessagePreviewOutlet: GiftMessagePreviewController

  static targets = ["addMessage", "previewMessage"]
  declare readonly addMessageTarget: HTMLElement
  declare readonly previewMessageTarget: HTMLElement

  removeMessage(): void {
    this.giftMessagePreviewOutlet.clearAll()

    this.addMessageTarget.hidden = false
    this.previewMessageTarget.hidden = true

    this.dispatch("removeMessage")
  }

  showPreview({ detail }: StimulusDispatchOptions<GiftMessageDetail>): void {
    this.giftMessagePreviewOutlet.setAll(detail!)

    this.addMessageTarget.hidden = true
    this.previewMessageTarget.hidden = false
  }
}
