import { Controller } from "@hotwired/stimulus"

import { debounce } from "@/helpers/utils"

const REQUIRED_TEXT = "Please fill out this field."

export default class extends Controller<HTMLFormElement> {
  static targets = ["applicableVoucherPrice", "code", "turboErrorStream"]

  declare readonly applicableVoucherPriceTarget: HTMLInputElement
  declare readonly codeTarget: HTMLInputElement

  initialize(): void {
    this.applyPromoToVoucher = debounce(this.applyPromoToVoucher.bind(this), 500)
  }

  applyPromoToVoucher(code: string): void {
    this.codeTarget.value = code
    this.element.requestSubmit()
  }

  clearError({ currentTarget }: IEvent<HTMLInputElement>): void {
    this.setErrorMessage(currentTarget, "")
  }

  resetForm(): void {
    this.codeTarget.value = ""
    this.setErrorMessage(this.codeTarget, "")
  }

  turboErrorStreamTargetConnected(errorElement: HTMLSpanElement): void {
    this.toggleValidity(errorElement, false)
    this.dispatch("discountError")
  }

  validate(e: Event): void {
    if (!this.validateRequiredInput(this.codeTarget)) {
      e.preventDefault()
    }
  }

  updateApplicableVoucherPrice(pricePounds: number): void {
    this.applicableVoucherPriceTarget.value = pricePounds.toString()
  }

  private labelFor(element: HTMLElement): HTMLLabelElement {
    return element.closest("label") as HTMLLabelElement
  }

  private toggleValidity(inputOrError: HTMLElement, condition: boolean): void {
    this.labelFor(inputOrError).classList.toggle("invalid", !condition)
  }

  private setErrorMessage(inputOrError: HTMLElement, message: string): void {
    const errorElement = this.labelFor(inputOrError).querySelector(".utils\\:error")!

    this.toggleValidity(inputOrError, message === "")
    errorElement.textContent = message
  }

  private validateRequiredInput(input: HTMLInputElement): boolean {
    const valid = input.value.trim() !== ""
    if (!valid) {
      this.setErrorMessage(input, REQUIRED_TEXT)
    }

    return valid
  }
}
